import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import * as csStyles from "../../styles/cs.module.css"

export default function OCT() {
	return (
		<Layout>
			
			<header className={csStyles.csHeaderWrapper} id="top">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<h1>Ontario College of Teachers Website</h1>
							<p>Redesigning the website to build trust and improve engagement with parents.</p>
						</div>
					</div>
				</div>
				<StaticImage src="../../images/casestudy/oct/featured-image.jpg" layout="fullWidth" className={csStyles.csFeaturedImage} alt=""/>
			</header>
			
			<main className={csStyles.csContentWrapper}>
				
				<section className={`container-fluid ${csStyles.addPadding}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Overview</h2>
							<p>Working in a team of 6 UX designers, my colleagues and I were tasked with the opportunity to work directly with the Ontario College of Teachers for a design course. Our challenge was to explore different ways we could improve the overall usability and accessibility of the website, specifically for parents of school children.</p>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding} ${csStyles.csInfo} text-center`}>
					<div className="row justify-content-center">
						<div className="col-md-2">
							<h3>My Role</h3>
							<ul className="list-unstyled">
								<li>User Research</li>
								<li>Accessibility Audit</li>
								<li>User Persona</li>
								<li>User Testing</li>
								<li>HTML Prototype</li>
								<li>Poster Design</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3>Timeline</h3>
							<ul className="list-unstyled">
								<li>3 months</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3>Tools</h3>
							<ul className="list-unstyled">
								<li>Figma</li>
								<li>HTML + CSS</li>
								<li>WAVE</li>
								<li>Optimal Workshop</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3>Team</h3>
							<ul className="list-unstyled">
								<li>Claudia Szabuniewicz</li>
								<li>Michael Plotnikov</li>
								<li>Pei Xian Chia</li>
								<li>Sabrina Li</li>
								<li>Sneha Parekh</li>
							</ul>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>About OCT</h2>
							<p>Ontario College of Teachers (OCT) is a regulatory body in Ontario that licenses, governs, and regulates the teaching profession in the public interest. All public school teachers and administrators in Ontario must be registrants of the College.</p>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding} ${csStyles.csProblem}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Problem</h2>
							<p>OCT maintains an online database of certified teachers and provides details about teacher accreditation, standards of practice, disciplinary procedures, and more through its website.</p>
							<p>Informed by internal research efforts, OCT wanted to better highlight these services on their website in order to boost engagement with parents of school children and to build public confidence in the teaching profession.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<blockquote className={`blockquote ${csStyles.blockquote}`}>
								How might we improve the OCT website to build trust and improve engagement with parents?
							</blockquote>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Goals</h2>
							<p>Our goal was to redesign the OCT website to align with its target audience's priorities and improve its overall usability and accessibility in order to support OCT's mandate of serving the public interest.</p>
							<p>Target Users: Ontarian parents of school-aged children, who have basic technological competence and are <em>not</em> part of the teaching community.</p>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding} ${csStyles.csProcess} text-center`}>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<h2>Process</h2>
						</div>
					</div>
					<div className={`row justify-content-center ${csStyles.csProcessVisual}`}>
						<div className="col-md-2">
							<h3><span className={csStyles.emoji}>🤝</span><br/><br/>Empathize</h3>
							<ul className="list-unstyled">
								<li>User Interviews</li>
								<li>Usability Testing</li>
								<li>Accessibility Audit</li>
								<li>Card Sorting</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3><span className={csStyles.emoji}>🎯</span><br/><br/>Define</h3>
							<ul className="list-unstyled">
								<li>Research Synthesis</li>
								<li>User Persona</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3><span className={csStyles.emoji}>💡</span><br/><br/>Ideate</h3>
							<ul className="list-unstyled">
								<li>Comparative Analysis</li>
								<li>Information Architecture</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3><span className={csStyles.emoji}>🖌</span><br/><br/>Prototype</h3>
							<ul className="list-unstyled">
								<li>Low Fidelity</li>
								<li>High Fidelity</li>
								<li>HTML Prototype</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3><span className={csStyles.emoji}>🔍</span><br/><br/>Test</h3>
							<ul className="list-unstyled">
								<li>Usability Testing</li>
								<li>Tree Testing</li>
								<li>A/B Testing</li>
								<li>First-Click Testing</li>
							</ul>
						</div>
					</div>
				</section>
				
				<section className={`container-fluid ${csStyles.addPadding}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>User Interviews</h2>
							<p>To gain a better understanding of how we could more effectively address the needs of parents, we conducted four one-on-one semi-structured interviews with target users identified from our personal networks. This helped us to gauge the context of parents' engagement with their children's teachers and the OCT.</p>
							<h3 className={csStyles.smallHeading}>Sample Interview Questions:</h3>
							<ul className="list-unstyled">
								<li>"How do you communicate with your children's teachers?"</li>
								<li>"If you have a concern about a teacher, how do you go about addressing it?"</li>
								<li>"What does teacher credibility mean to you?"</li>
							</ul>
							<h3 className={csStyles.smallHeading}>Key Findings:</h3>
							<ul>
								<li>Parents' main priorities were related to their children's safety and growth in the classroom.</li>
								<li>Teachers' personal qualities and their care and enthusiasm for kids were more important to parents than their formal qualifications.</li>
								<li>Parents were not familiar with the OCT as the regulatory body for teachers.</li>
							</ul>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-10">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/oct/current-website.jpg" layout="fullWidth" alt="Screenshots of current OCT website"/>
								<figcaption className="figure-caption">Screenshots of current OCT website (homepage ads and social media widgets not displayed)</figcaption>
							</figure>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Usability Testing</h2>
							<p>Next, we conducted usability tests with three parents who had no prior experience with the OCT website in order to assess its functionality and intuitiveness.</p>
							<p>Participants were asked to complete the following tasks that were identified through our research as the main features of the website:</p>
							<ol>
								<li>Find a specific teacher's qualifications</li>
								<li>Submit a complaint</li>
								<li>Find information on teaching standards and ethics</li>
							</ol>
							<h3 className={csStyles.smallHeading}>Key Findings:</h3>
							<ul>
								<li>
									<p><strong>Cluttered homepage.</strong> Participants did not understand the purpose of the website from the homepage and complained about "too much text". They also expressed doubts regarding the credibility of the website due to multiple social media ads and banners.</p>
								</li>
								<li>
									<p><strong>Complicated information architecture.</strong> Participants were frustrated with the website navigation, which included three layers of links nested deeply within dropdown menus. They did not notice the secondary navigation or breadcrumbs in the subpages.</p>
								</li>
								<li>
									<p><strong>Low findability and discoverability.</strong> Participants felt the information on the website was irrelevant to them and had difficulty completing the tasks. For example, participants missed the "Find a Teacher" feature despite it being on the homepage.</p>
								</li>
							</ul>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Accessibility Audit</h2>
							<p>We also conducted an accessibility audit of the website using a combination of semi-automated and manual evaluation tools such as WAVE, colour contrast checkers, and screen readers.</p>
							<p>The audit revealed that the website did not comply with WCAG 2.0 Level AA guidelines — the most prominent issues being low colour contrast in text and poor alt text for informative images.</p>
							<p>These issues, along with recommended fixes, were compiled into a report and delivered to the client.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/oct/accessibility-audit.jpg" layout="fullWidth" alt="Accessibility Audit Report"/>
								<figcaption className="figure-caption">Accessibility Audit Report</figcaption>
							</figure>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding} ${csStyles.csSynthesis}`}>
					<div className="row justify-content-center text-center">
						<div className="col-md-9">
							<h2>Research Synthesis</h2>
							<p>After analyzing all insights gleaned from our user research, we defined the following objectives for the redesign:</p>
						</div>
					</div>
					<div className={`row justify-content-center ${csStyles.csSynthesisVisual}`}>
						<div className="col-md-3">
							<p><span className={csStyles.number}>1.</span><br/>
							Communicate key messages that are relevant to parents clearly and concisely.</p>
						</div>
						<div className="col-md-3">
							<p><span className={csStyles.number}>2.</span><br/>
							Improve information architecture to make important content more findable and discoverable.</p>
						</div>
						<div className="col-md-3">
							<p><span className={csStyles.number}>3.</span><br/>
							Follow WCAG 2.0 Level AA accessibility standards to comply with AODA (Accessibility for Ontarians with Disabilities Act).</p>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>User Persona</h2>
							<p>Using the interview data, we constructed a user persona, Natalie, to map the goals, motivations, and frustrations of parents, which guided us through the redesign process.</p>
							<p>Natalie is actively involved in her children's education and wants easy access to information about their teachers' personal and professional qualifications.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/oct/persona.jpg" layout="fullWidth" alt="Persona: Natalie Wagner"/>
								<figcaption className="figure-caption">Persona: Natalie Wagner</figcaption>
							</figure>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Comparative Analysis</h2>
							<p>To gain inspiration and identify best practices for the redesign, we began by looking at landing pages of similar regulatory bodies in Ontario with a specific focus on their information structure and layout.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/oct/comparative-analysis-organizations.jpg" layout="fullWidth" alt="Comparative Analysis: Regulatory bodies in Ontario"/>
								<figcaption className="figure-caption">Comparative Analysis: Regulatory bodies in Ontario</figcaption>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<p>One common feature we identified across these websites was the placement of call-to-action buttons right below the banner, emphasizing the main features of the website. This was an important design pattern to consider in order to make it easier and more efficient for users to access key information.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/oct/comparative-analysis-cta.jpg" layout="fullWidth" alt="Comparative Analysis: Placement of CTAs"/>
								<figcaption className="figure-caption">Comparative Analysis: Placement of CTAs</figcaption>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<p>Another pattern we observed was that organizations displayed their mission statement clearly on their homepage. This effectively communicated their mandate and helped users better understand what the organization did. The 'Find a [professional]' feature was also prominent, which allowed users to easily look up information about specific individuals.</p>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Information Architecture</h2>
							<p>After reviewing the website structures of similar organizations, we conducted six open card sorts with parents and members of the public to understand users' perception of the information space. The card sorting was conducted remotely using OptimalSort and it informed the following information schematic:</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-10">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/oct/ia-schematic.jpg" layout="fullWidth" alt="Information Architecture Schematic"/>
								<figcaption className="figure-caption">Information Architecture Schematic</figcaption>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<p>To validate the assumptions made in the grouping of content, we conducted a tree test administered remotely via Treejack with 10 participants. Based on the results, certain links were relabeled or cross-linked under different sections to reinforce user expectations and mental models.</p>
							<p>For example, removing the 'Discipline' category from 'Public' as users did not find content under this heading to be relevant to their needs, and changing the label 'Employers' to 'Partners' to better reflect the audiences served.</p>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Design and Testing</h2>
							<p>Once we had a good idea about the website structure, we brainstormed ideas for the layout on paper and then moved on to creating wireframes.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-10">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/oct/wireframes.jpg" layout="fullWidth" alt="Wireframes"/>
								<figcaption className="figure-caption">Wireframes</figcaption>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<p>We took an iterative testing approach to progress the design of the website to the final HTML prototype stage. The designs were tested, iterated, and validated using a combination of A/B, first-click, and usability testing methods.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h3>Homepage</h3>
							<p>To showcase the organization's core offerings in a way that appealed to parents, we carefully curated the content on the home page.</p>
							<p>We incorporated the organization's brand colours in the website header for a more professional look and chose an eye-catching hero banner that appealed to user's emotions. We also removed the ads, reduced the preponderance of social media links, and improved the efficiency of finding information with a list of 'Quick Links' in the footer.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-10">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/oct/new-homepage.jpg" layout="fullWidth" alt="Updated Homepage"/>
								<figcaption className="figure-caption">Updated Homepage</figcaption>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<p><strong>Outcome:</strong> Users liked the blue and white colour scheme, noting its simplicity and minimalist aesthetic. One user noted that the layout of the homepage was 'sleek' and gave way for a 'clean and professional' feel.</p>
							<p>Next, we added calls-to-action outlining the three offerings that aligned with parents' priorities, the highlighted tab being 'Find a Teacher' because of its popularity.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-10">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/oct/homepage-comparison.jpg" layout="fullWidth" alt="Homepage Comparison: Before and After"/>
								<figcaption className="figure-caption">Homepage Comparison: Before and After</figcaption>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<p><strong>Outcome:</strong> There was a 100% task success rate of locating the 'Find a Teacher' feature after the redesign. Users reported being able to determine the purpose of the website quickly because of the centrality of the 'Find a Teacher', 'Submit a Complaint', and 'Standards of Practice' features.</p>
							<blockquote className={`blockquote ${csStyles.blockquote}`}>
								"I really like the 'Find A Teacher' function and that it stands out on the page."
							</blockquote>
							<p>We also highlighted OCT's mandate with a short introduction on the homepage to provide users with context about the organization.</p>
							<p><strong>Outcome:</strong> Users were able to clearly determine the College's purpose from the homepage, and one user reported that the website gave the impression that it was targeted specifically towards parents.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h3>Navigation</h3>
							<p>For the global navigation, we decided to incorporate a mega menu. We eliminated the third layer of navigation in the new design to increase mobile-friendliness and reduce the number of clicks it takes to find information.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-10">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/oct/navigation-comparison.jpg" layout="fullWidth" alt="Navigation Comparison: Before and After"/>
								<figcaption className="figure-caption">Navigation Comparison: Before and After</figcaption>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<p><strong>Outcome:</strong> The mega menu was reported to be very effective by users, particularly with the inclusion of descriptive labels to group related content into distinct sub-categories (e.g. Protecting the Public).</p>
							<blockquote className={`blockquote ${csStyles.blockquote}`}>
								"The menu is very straightforward and tells me what each heading is, and I can understand what would show up if I clicked it."
							</blockquote>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h3>Subpages</h3>
							<p>For the secondary navigation in the subpages, we relocated the side menu from the right to the left to increase its visibility. User testing showed a preference for local navigation on the left-hand side, which provided a clear overview of relevant content, consistent with existing F-pattern reports of eye-tracking research.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-10">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/oct/subpage-comparison.jpg" layout="fullWidth" alt="Subpage Comparison: Before and After"/>
								<figcaption className="figure-caption">Subpage Comparison: Before and After</figcaption>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<p><strong>Outcome:</strong> Users found that the organization of the menu links was intuitive, and they were able to navigate using the menus to finish their assigned tasks without issue.</p>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Final Deliverables</h2>
							<p>After validating the designs, we created an HTML prototype demonstrating the desktop experience. <a href="https://oct-demo.netlify.com/" target="_blank" rel="noreferrer">View prototype →</a></p>
							<p>We also put together a poster outlining all of our research methods, key insights, and recommendations for the client. <a href="https://drive.google.com/file/d/1fn1QQt6qb9YkqB7YuwR56W0gLkxxQJVW/view?usp=sharing" target="_blank" rel="noreferrer">View poster →</a></p>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding} ${csStyles.csFeedback} text-center`}>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<blockquote className={`blockquote ${csStyles.blockquote}`}>
								"I was so impressed by the rigour and depth of your analysis, your solutions, your insight... the whole package. Thank you so much!"
							</blockquote>
							<p>Mark Farmer, Senior Digital Media Strategist at OCT</p>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding} ${csStyles.csReflections}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Reflections</h2>
							<ol>
								<li>
									<p><strong>User personas give a solid defence against scope creep.</strong> Given the numerous possibilities of how we could redesign the website, having Natalie, our persona, at the forefront of all design decisions helped to define the scope and manage expectations.</p>
								</li>
								<li>
									<p><strong>Maintaining regular communication with stakeholders helps create a shared vision for success.</strong> We had an open communication channel with our client representative which really helped ensure we were on the same page at every step of the design process.</p>
								</li>
								<li>
									<p><strong>Ensuring accessibility from the outset is better than retrofitting it.</strong> We developed a UI style guide following accessibility guidelines which made it easier for us to attain cohesiveness in our final designs at the later stages.</p>
								</li>
							</ol>
						</div>
					</div>
				</section>

			</main>

			<div className={`container ${csStyles.csFooter}`}>
				<div className="row">
					<div className="col-md-6 col-sm-6 col-xs-6">
						<div id="previous">
							<div className="prev-project">
								<h4>↑</h4><a href="#top">Back to Top</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-6 col-xs-6">
						<div id="next">
							<div className={csStyles.nextProject}>
								<h4>Next project</h4><Link to="/work/allwell">AllWell Medical App</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

		</Layout>
	)
}